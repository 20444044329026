import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="terms"
export default class extends Controller {
  static targets = ["checkbox", "submitButton"]

  connect() {
    this.toggleSubmitButton(); // Set the initial state
  }

  toggleSubmitButton() {    
    if (this.checkboxTarget.checked) {
      this.submitButtonTarget.disabled = false;
      this.submitButtonTarget.classList.remove('btn-disabled');
      this.submitButtonTarget.classList.add('btn-primary');
    } else {
      this.submitButtonTarget.disabled = true;
      this.submitButtonTarget.classList.add('btn-disabled');
      this.submitButtonTarget.classList.remove('btn-primary');
    }
  }
}
