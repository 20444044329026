document.addEventListener('turbo:load', function() {
  
  // Prevent form submit until RECAPTCHA validation
  let recaptchaForm = document.getElementById('recaptcha-form');

  if (recaptchaForm) {
      recaptchaForm.addEventListener('submit', function(evt) {
          let gRecaptchas = document.querySelectorAll('.g-recaptcha');
  
          if (gRecaptchas.length > 1) {
              let response = grecaptcha.getResponse();
  
              if (response == "") {
                  evt.preventDefault();
                  return false;
              }
          }
  
          return evt.target.checkValidity();
      });
  }
});
