import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="get-sale-assessment-details"
export default class extends Controller {
  static targets = ["form", "saleSelect"]

  updateSaleDetails(event) {
    // Prevent the default form submission
    event.preventDefault()
    
    // Use requestSubmit() to submit the form
    this.formTarget.requestSubmit()
  }
}
