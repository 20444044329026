import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {
  static targets = ["toggle", "ebv", "lots"];

  connect() {
    this.toggleTarget.addEventListener("change", this.toggleViewerMode.bind(this));
  }

  toggleViewerMode() {
    if (this.toggleTarget.checked) {
      
      this.lotsTargets.forEach((bidder) => bidder.classList.remove("hide"));
      this.ebvTargets.forEach((bidder) => bidder.classList.add("hide"));
      // Execute your JavaScript code for Bidder Mode On here
    } else {
      this.lotsTargets.forEach((bidder) => bidder.classList.add("hide"));
      this.ebvTargets.forEach((bidder) => bidder.classList.remove("hide"));
      // Execute your JavaScript code for Bidder Mode Off here
    }
  }
}
