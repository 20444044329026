import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sticky"
export default class extends Controller {
  static targets = ["stickyDiv", "filterTag", "header", "stepper", "filterDiv", "jumpToSection"];

  connect() {
    if (this.hasHeaderTarget) {
      this.headerOriginalOffsetY = this.headerTarget.offsetTop;
      this.headerPlaceholder = document.createElement('div');
      this.headerPlaceholder.style.height = `${this.headerTarget.offsetHeight}px`;
      this.headerPlaceholder.className = 'header-placeholder';

      this.onScrollHeader = this.throttle(this.onScrollHeader.bind(this), 400);
      window.addEventListener('scroll', this.onScrollHeader);
    }

    if (this.hasStepperTarget) {
      this.stepperOriginalOffsetY = this.stepperTarget.offsetTop;
      this.stepperPlaceholder = document.createElement('div');
      this.stepperPlaceholder.style.height = `${this.stepperTarget.offsetHeight}px`;
      this.stepperPlaceholder.className = 'stepper-placeholder';

      this.onScrollStepper = this.throttle(this.onScrollStepper.bind(this), 400);
      window.addEventListener('scroll', this.onScrollStepper);
    }

    if (this.hasJumpToSectionTarget) {
      this.jumpToSectionOriginalOffsetY = this.jumpToSectionTarget.offsetTop;
      this.onScrollJumpToSection = this.onScrollJumpToSection.bind(this);
      window.addEventListener('scroll', this.onScrollJumpToSection);
    }

    if (this.hasStickyDivTarget && this.hasFilterTagTarget) {
      window.addEventListener('scroll', () => {
        var rect = this.stickyDivTarget.getBoundingClientRect();
        if (rect.top <= 0) {
          this.filterDivTarget.classList.add('sticky-div');
        } else {
          this.filterDivTarget.classList.remove('sticky-div');
        }
      });
    }
  }

  disconnect() {
    if (this.onScrollHeader) {
      window.removeEventListener('scroll', this.onScrollHeader);
    }
    if (this.onScrollStepper) {
      window.removeEventListener('scroll', this.onScrollStepper);
    }
    if (this.onScrollJumpToSection) {
      window.removeEventListener('scroll', this.onScrollJumpToSection);
    }
  }

  throttle(func, limit) {
    let lastFunc;
    let lastRan;
    return function() {
      const context = this;
      const args = arguments;
      if (!lastRan) {
        func.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(function() {
          if ((Date.now() - lastRan) >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  }

  onScrollHeader() {
    if (window.pageYOffset > this.headerOriginalOffsetY) {
      this.headerTarget.classList.add('sticky-header');
      if (!document.querySelector('.header-placeholder')) {
        this.headerTarget.parentNode.insertBefore(this.headerPlaceholder, this.headerTarget);
      }
    } else {
      this.headerTarget.classList.remove('sticky-header');
      const existingHeaderPlaceholder = document.querySelector('.header-placeholder');
      if (existingHeaderPlaceholder) {
        existingHeaderPlaceholder.parentNode.removeChild(existingHeaderPlaceholder);
      }
    }
  }

  onScrollStepper() {
    if (window.pageYOffset > this.headerOriginalOffsetY + this.headerTarget.offsetHeight) {
      this.stepperTarget.classList.add('sticky-stepper');
      this.stepperTarget.style.top = `${this.headerTarget.offsetHeight}px`;
      if (!document.querySelector('.stepper-placeholder')) {
        this.stepperTarget.parentNode.insertBefore(this.stepperPlaceholder, this.stepperTarget);
      }
    } else {
      this.stepperTarget.classList.remove('sticky-stepper');
      this.stepperTarget.style.top = '0px';
      const existingStepperPlaceholder = document.querySelector('.stepper-placeholder');
      if (existingStepperPlaceholder) {
        existingStepperPlaceholder.parentNode.removeChild(existingStepperPlaceholder);
      }
    }
  }

  onScrollJumpToSection() {
    if (window.pageYOffset > this.jumpToSectionOriginalOffsetY - this.headerTarget.offsetHeight - this.stepperTarget.offsetHeight) {
      this.jumpToSectionTarget.classList.add('sticky-jump-to-section');
      this.jumpToSectionTarget.style.top = `${this.headerTarget.offsetHeight + this.stepperTarget.offsetHeight}px`;
      this.jumpToSectionTarget.style.width = `${this.jumpToSectionTarget.parentElement.offsetWidth}px`; // Set the width
    } else {
      this.jumpToSectionTarget.classList.remove('sticky-jump-to-section');
      this.jumpToSectionTarget.style.top = '0px';
      this.jumpToSectionTarget.style.width = 'auto'; // Reset the width
    }
  }
}
