//Turbo.session.drive = false
import "core-js/stable"
import "regenerator-runtime/runtime"
import "debounce"

import jQuery from "jquery"
window.jQuery = jQuery
window.$ = jQuery

import Select2 from 'select2'
Select2(window.jQuery)

import '@hotwired/turbo-rails'
import '@hotwired/stimulus'
import "flowbite/dist/flowbite.turbo.js";
import ahoy from "ahoy.js";

import Rails from 'rails-ujs';
import * as ActiveStorage from 'activestorage';
import LocalTime from "local-time"
import '@fortawesome/fontawesome-free/js/all';

Rails.start();

ActiveStorage.start();
LocalTime.start()

window.materializeLoaded = new Promise((resolve) => {
  if (!window.useTailwind) {
    import('materialize-css/dist/js/materialize.min.js').then((MaterializeModule) => {
      window.M = MaterializeModule.default;
      resolve(window.M);
    }).catch(err => {
      console.error('Error loading Materialize:', err);
      resolve(null);
    });
  } else {
    resolve(null);
  }
});

window.materializeLoaded.then(() => {
  import('@hotwired/stimulus').then(({ Application }) => {
    const application = Application.start();
    
    import("./controllers").then(() => {
      import("./application/javascripts/custom.js");
      import("./application/javascripts/pages.js");
      import("./application/javascripts/sales.js");
    });
  });
});

import "./channels"
