const { start } = require("activestorage");

// Refactor initialization into separate functions
function initializeDropdowns() {
  document.querySelectorAll('.dropdown-trigger').forEach(element => {
    M.Dropdown.init(element);
  });
}

function updateTextFields() {
  M.updateTextFields();
}

function initializeSelects() {
  if (!window.M) return;
  
  document.querySelectorAll('select:not([data-initialized]):not(.select2)').forEach(element => {
    try {
      // Mark the element as initialized
      element.setAttribute('data-initialized', 'true');

      // Destroy old instances if they exist
      let instance = M.FormSelect.getInstance(element);
      if (instance) {
        instance.destroy();
      }

      // Initialize with safe options
      M.FormSelect.init(element, {
        dropdownOptions: {
          container: document.body
        }
      });
    } catch (error) {
      console.error('Error initializing select:', error, element);
    }
  });
}


function initializeCollapsibles() {
  document.querySelectorAll('.collapsible').forEach(element => {
    if (!element.classList.contains('reInit')) {
      M.Collapsible.init(element, { accordion: false });
    }
  });
}

function initializeTabs() {
  document.querySelectorAll('.tabs').forEach(element => {
    M.Tabs.init(element);
  }
  );
}

// Wait for Materialize to be loaded before initializing
function initializeWhenReady() {
  window.materializeLoaded.then((M) => {
    if (!M) return; // Skip if Materialize isn't loaded (Tailwind mode)
    
    initializeDropdowns();
    updateTextFields();
    initializeSelects();
    initializeCollapsibles();
    initializeTabs();
    initializeMobileFormat();
  }).catch(err => {
    console.error('Error initializing Materialize components:', err);
  });
}

// Initialize on both DOMContentLoaded and turbo:load
document.addEventListener('DOMContentLoaded', initializeWhenReady);
document.addEventListener('turbo:load', initializeWhenReady);

function initializeMobileFormat() {
  $(document).on('input', '.phone-format', function () {
    var numbers = $(this).val().replace(/\D/g, '');
    var char = {4: ' ', 7: ' '};
    this.value = '';
    for (var i = 0; i < numbers.length; i++) {
      this.value += (char[i] || '') + numbers[i];
    }
  }); 
}

$('button[data-action="nested-form#add"]').on('click', function() {
  // Wait for the new form to be added to the DOM
  setTimeout(function() {
    // Re-initialize Materialize select fields
    initializeSelects();
  }, 40);
});

// Allow for turbo frame to be able to load the javascript after loading the page through turbo frame
document.addEventListener('turbo:load', function() {
  handleShowHidePassword();
  let password = document.getElementById('password');
  let confirmPassword = document.getElementById('confirm_password');
  
  if (password) {
      password.addEventListener('focusout', function() {
          if (confirmPassword && password.value !== confirmPassword.value) {
              confirmPassword.classList.remove('valid');
              confirmPassword.classList.add('invalid');
          } else if (confirmPassword) {
              confirmPassword.classList.remove('invalid');
              confirmPassword.classList.add('valid');
          }
      });
  }
  
  if (confirmPassword) {
      confirmPassword.addEventListener('keyup', function() {
          if (password && password.value !== confirmPassword.value) {
              confirmPassword.classList.remove('valid');
              confirmPassword.classList.add('invalid');
          } else {
              confirmPassword.classList.remove('invalid');
              confirmPassword.classList.add('valid');
          }
      });
  }


});

function handleShowHidePassword() {
  document.querySelectorAll('.show-hide-icon').forEach(element => {
    element.addEventListener('click', function() {
      let passwordField = element.previousElementSibling;
      let passwordFieldType = passwordField.getAttribute('type');

      if (passwordFieldType === 'password') {
        passwordField.setAttribute('type', 'text');
        element.textContent = 'visibility_off';
      } else {
        passwordField.setAttribute('type', 'password');
        element.textContent = 'visibility';
      }
    });
  });
}

// Listen for clicks on the document
document.addEventListener('click', function(event) {
  // Check if the clicked element is a sidenav trigger
  if (event.target.closest('.sidenav-trigger')) {
    event.preventDefault();
    openSidenav(event.target.closest('.sidenav-trigger').dataset.target);
  }
  if (event.target.closest('.modal-trig')) {
    event.preventDefault();
    openModal(event.target.closest('.modal-trig').dataset.modalId);
  }
});

function openModal(modalId) {
  let modalElement = document.getElementById(modalId);
  if (!modalElement) return;

  // Initialize the modal if not already initialized
  let modalInstance = M.Modal.getInstance(modalElement);
  if (!modalInstance) {
      modalInstance = M.Modal.init(modalElement);
  }

  // Open the modal
  modalInstance.open();
}

function openSidenav(sidenavId) {
  let sidenavElement = document.getElementById(sidenavId);
  if (!sidenavElement) return;

  // Initialize the sidenav if not already initialized
  if (!M.Sidenav.getInstance(sidenavElement)) {
    M.Sidenav.init(sidenavElement);
  }

  // Open the sidenav
  let sidenavInstance = M.Sidenav.getInstance(sidenavElement);
  sidenavInstance.open();

  // Add an event listener for the close event
  sidenavElement.addEventListener('sidenav:close', function() {
    document.body.classList.remove('sidenav-open');
  });
}


// For the desktop floating action button on most pages
document.addEventListener("turbo:load", function() {
  let isMobileOrTablet = window.innerWidth <= 768; // You can adjust this threshold as needed

  M.FloatingActionButton.init(document.querySelectorAll('.fixed-action-btn'), {
    hoverEnabled: !isMobileOrTablet, // Disable hover on mobile and tablets
    direction: 'top'
  });
});

// Handle nested form additions
document.addEventListener('nested-form:added', () => {
  window.materializeLoaded.then((M) => {
    if (!M) return;
    setTimeout(initializeSelects, 0);
  });
});

