import { Controller } from "@hotwired/stimulus"
import debounce from "debounce";

// Connects to data-controller="search"
export default class extends Controller {
  static targets = ["loading", "search_icon"];
  initialize() {
    this.submit = debounce(this.submit.bind(this), 500)
  }
  submit() {
    let self = this;
    document.addEventListener("turbo:before-fetch-request", function(){
      self.search_iconTarget.setAttribute("style", "display: none");
      self.loadingTarget.setAttribute("style", "display: inline-block");
  })
    this.element.requestSubmit();
    
    
    document.addEventListener("turbo:frame-render", function() {
      self.loadingTarget.setAttribute("style", "display: none");
      self.search_iconTarget.setAttribute("style", "display: inline-block");
  })
  
}
}

