import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    placeholder: String,
    maxItems: Number
  }

  connect() {
    this.initializeSelect2()
  }

  initializeSelect2() {
    const element = $(this.element)    
    const options = {
      placeholder: this.placeholderValue || '',
      width: '100%'
    }

    if (element.attr('multiple')) {
      options.maximumSelectionLength = this.maxItemsValue || 3
    }

    element.select2(options)
  }

  disconnect() {
    const element = $(this.element)
    if (element.data('select2')) {
      element.select2('destroy')
    }
  }
}
