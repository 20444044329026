import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bid-increment-calculator"
export default class extends Controller {
  static targets = ["reservePrice", "startingBid"]
  static values = { increment: Number }

  connect() {
    this.calculateStartingBid()
  }

  calculateStartingBid() {
    const reservePrice = parseFloat(this.reservePriceTarget.value)
    if (this.isValidNumber(reservePrice)) {
      const startingBid = Math.max(0, reservePrice - (2 * this.incrementValue))
      this.startingBidTarget.value = this.formatBid(startingBid)
    } else {
      this.startingBidTarget.value = ""
    }
  }

  formatBid(value) {
    return parseFloat(value).toString(); // Converts to string without trailing zeros
  }

  isValidNumber(value) {
    return !isNaN(value) && value > 0
  }
}
