import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["status", "weightsCsvField"];

  connect() {
    this.loadCSVBoxScript().then(() => {
      this.initializeCSVBoxImporter();
    }).catch(error => {
      console.error("Failed to load CSVBox script:", error);
    });
  }

  loadCSVBoxScript() {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://js.csvbox.io/script.js";
      script.onload = () => resolve();
      script.onerror = () => reject(new Error("Failed to load script"));
      document.head.appendChild(script);
    });
  }

  initializeCSVBoxImporter() {
    const callback = (result, data) => {
      const statusElement = this.statusTarget;
      if (result) {
        statusElement.innerText = `Weights uploaded successfully. ${data.row_success} Animal Weights uploaded.`;
        statusElement.style.color = "green";
        // Assuming data.url contains the URL of the uploaded file
        this.weightsCsvFieldTarget.value = data.url;
      }
    };

    this.csvBoxImporter = new CSVBoxImporter(this.data.get("key"), {}, callback);
    this.csvBoxImporter.setUser({
      user_id: this.data.get("userId"),
      assessment_id: this.data.get("assessmentId")
    });
  }

  openModal(event) {
    event.preventDefault();
    this.csvBoxImporter.openModal();
  }
}