import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bidder-mode"
export default class extends Controller {
  // get the icons for the page you wish to hideshow
  static targets = ["switch", "managementicons", "biddericons"];

  connect() {
    this.switchTarget.addEventListener("change", this.toggleBidderMode.bind(this));
  }

  toggleBidderMode() {
    if (this.switchTarget.checked) {
      this.biddericonsTargets.forEach((bidder) => bidder.classList.add("hide"));
      this.managementiconsTargets.forEach((bidder) => bidder.classList.remove("hide"));
      // Execute your JavaScript code for Bidder Mode On here
    } else {
      this.biddericonsTargets.forEach((bidder) => bidder.classList.remove("hide"));
      this.managementiconsTargets.forEach((bidder) => bidder.classList.add("hide"));
      // Execute your JavaScript code for Bidder Mode Off here
    }
  }
}
