import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toast"
export default class extends Controller {
  static values = {
    timeout: { type: Number, default: 3000 } // Default timeout of 5000 ms (5 seconds)
  }

  connect() {
    // Automatically dismiss the toast after the specified timeout
    setTimeout(() => {
      this.dismiss();
    }, this.timeoutValue);
  }

  dismiss() {
    // Remove the toast element from the DOM
    this.element.remove();
  }
}
