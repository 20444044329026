// app/javascript/controllers/dropzone_controller.js
import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";

export default class extends Controller {
  static values = {
    assessmentId: String,
    media: Array,
    coverImageId: Number,
    endpointContext: String,
    endpointUrl: String
  }

  async connect() {
    let acceptedFiles = "image/png,image/jpeg,image/jpg,image/webp,video/*";
    let maxFiles = 20;
    let previewTemplate = `
      <div class="col s6 l3 photo">
        <div class="card card-flex" style="height: 180px;">
          <div class="card-image">
            <div class="dz-preview dz-file-preview">
              <div class="dz-details">
                  <img class="materialboxed image-sizing" data-dz-thumbnail />
              </div>
            </div>
            <div class="remove btn-floating halfway-fab waves-effect waves-light red" style="top: 5px; right: 5px;" data-dz-remove>
              <i class="material-icons">clear</i>
            </div>
          </div>
        </div>
        <div class="progress">
          <div class="determinate" style="width: 100%" data-dz-uploadprogress></div>
        </div>
        <div>
        <p>
        <label>
          <input type="checkbox" data-action="change->onechecked#deselectOthers" data-onechecked-target="checkbox" />
          <span>Cover image</span>
        </label>
      </p>
        </div>
      </div>
    `;

    if (this.endpointContextValue === "health") {
      acceptedFiles = "image/png,image/jpeg,image/jpg,image/webp";
      maxFiles = 5;
      previewTemplate = `
        <div class="col s6 l3 photo">
          <div class="card card-flex" style="height: 180px;">
            <div class="card-image">
              <div class="dz-preview dz-file-preview">
                <div class="dz-details">
                    <img class="materialboxed image-sizing" data-dz-thumbnail />
                </div>
              </div>
              <div class="remove btn-floating halfway-fab waves-effect waves-light red" style="top: 5px; right: 5px;" data-dz-remove>
                <i class="material-icons">clear</i>
              </div>
            </div>
          </div>
          <div class="progress">
            <div class="determinate" style="width: 100%" data-dz-uploadprogress></div>
          </div>
        </div>
      `;
    }
    const endpointContext = this.endpointContextValue;
    const dropzoneConfig = {
      url: this.endpointUrlValue,
      method: "post",
      clickable: true,
      paramName: "media",
      maxFilesize: 250,
      parallelUploads: "8",
      dictDefaultMessage: "Drag and drop images/videos here or click to upload",
      addRemoveLinks: false,
      acceptedFiles: acceptedFiles,
      uploadMultiple: false,
      maxFiles: maxFiles,
      previewsContainer: `.${endpointContext}-dropzone-previews`,
      previewTemplate: previewTemplate,
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
      },
      init: function() {
        // Set a fixed height for the preview element
        this.on("addedfile", function(file) { 

          if (endpointContext !== "health") {
            const checkbox = file.previewElement.querySelector("input[type='checkbox']");
            checkbox.id = `media_${file.id}`;
            checkbox.value = file.id;
          }

          if (file.type.match(/image.*/)) {
            // Count the number of existing image files
            const imageFiles = this.files.filter(f => f.type.match(/image.*/)).length;

            // If there are already 8 images, prevent adding the new file and alert the user
            if (imageFiles > 8) { // Using > 8 because the current file has already been added to the count
              this.removeFile(file); // Remove the newly added file
              M.toast({html: "Failed to upload the file, max 8 images allowed", classes: 'red lighten-1'})
              return; // Stop further execution
            }
            
          }
          if (file.type.match(/video.*/)) {
            const video = document.createElement("video");
            video.preload = 'metadata';
            video.muted = true; // Mute the video
            video.onloadedmetadata = function() {
              window.URL.revokeObjectURL(video.src);
              const duration = video.duration;
              if (duration > 180) { // Replace 180 with your desired maximum duration in seconds
                this.removeFile(file);
                alert("Video is too long. Please select a video that is 180 seconds or less.");
              }
            }.bind(this);
            if (file.dataURL) {
              video.src = file.dataURL;
            } else {
              video.src = URL.createObjectURL(file);
            }
        
            // Create a video preview
            const previewElement = file.previewElement;
            const thumbnailElement = previewElement.querySelector("[data-dz-thumbnail]");
            thumbnailElement.parentNode.replaceChild(video, thumbnailElement);
            
            // Add Styling to the video
            video.classList.add("responsive-video");
            video.classList.add("materialboxed");
            video.style.width = '100%'; // Set the width
            video.style.height = 'auto'; // Set the height
            
            // Add video controls
            video.controls = true;
          } else {
            // Initialize materialbox with proper Materialize check
            if (window.M && window.M.Materialbox) {
              window.M.Materialbox.init(document.querySelectorAll('.materialboxed'))
            } else {
              // Wait for Materialize to be available
              const checkInterval = setInterval(() => {
                if (window.M && window.M.Materialbox) {
                  window.M.Materialbox.init(document.querySelectorAll('.materialboxed'))
                  clearInterval(checkInterval)
                }
              }, 100)

              // Stop checking after 5 seconds
              setTimeout(() => {
                clearInterval(checkInterval)
                if (!window.M?.Materialbox) {
                  console.error("Materialize Materialbox not available after timeout")
                }
              }, 5000)
            }
          }
        });
        // Event listener for file removal
        this.on("removedfile", function(file) {
          // Check if the file has an ID (meaning it's already uploaded)
          if (file.id) {
            // Send a DELETE request to your server
            fetch(`${this.options.url}/${file.id}`, {
              method: 'DELETE',
              headers: {
                "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
                "Content-Type": "application/json"
              },
              credentials: 'include' // Include cookies
            })
            .then(response => {
              if (response.ok) {
                M.toast({html: 'File deleted successfully'})
              } else {
                M.toast({html: "Failed to delete the file", classes: 'red lighten-1'})
                // Optionally, re-add the file preview if the deletion failed
              }
            })
            .catch(error => M.toast({html: "Failed to delete the file", classes: 'red lighten-1'}));
          }
        });
          // Success event handler
          this.on("success", function(file, response) {
            // Assuming 'response' contains the necessary data to update the preview
            if (response && response.id) {
              M.toast({html: 'Uploaded image successfully'})
              file.id = response.id;
          
              // Find the preview element for the uploaded file
              const previewElement = file.previewElement;
          
              // Update the preview element based on the response
              // This example assumes you want to update the image's src attribute
              // You might need to adjust this based on your actual response structure and what you want to update
              const imageElement = previewElement.querySelector("[data-dz-thumbnail]");
              if (imageElement) {
                // Assuming the response contains a new URL for the uploaded file
                imageElement.src = response.service_url; // Replace 'newImageUrl' with the actual property name from your response
              }
          
              // If you have a video and its URL needs to be updated, you can do a similar update for video elements
              const videoElement = previewElement.querySelector("video");
              if (videoElement) {
                videoElement.src = response.service_url; // Similarly, replace 'newVideoUrl' with the actual property name
              }
          
              // If you need to completely reload the component, you can remove the existing preview and add a new one
              // This would depend on how your previews are structured and might require more custom logic
            } else {
              M.toast({html: "No ID found in the response", classes: 'red lighten-1'})
            }
          });

          this.on("maxfilesexceeded", function(file) {
            this.removeFile(file); // Automatically remove files that exceed the maxFiles limit
          });

          this.on("error", function(file, response) {
            let errorMessage = "Failed to upload file."; // Default error message
            if (typeof response === "string") {
              errorMessage = response; // Use the server-provided error message
            } else if (response && response.message) {
              errorMessage = response.message; // Use a custom property of the response object
            }
          
            // Display the error message using Materialize toast
            M.toast({html: errorMessage, classes: 'red lighten-1'});
          
            // Remove the file preview from Dropzone
            this.removeFile(file);
          
            // Optionally, handle additional UI changes or logging
          });
      },
    };

    this.dropzone = new Dropzone(this.element, dropzoneConfig);

    // Sort the mediaValue array in ascending order by id
    this.mediaValue.sort((a, b) => a.id - b.id);

    // Find the cover image and move it to the start of the array
    const coverImageIndex = this.mediaValue.findIndex(media => media.id === this.coverImageIdValue);
    if (coverImageIndex > -1) {
      const coverImage = this.mediaValue.splice(coverImageIndex, 1)[0];
      this.mediaValue.unshift(coverImage);
    }

    for (const media of this.mediaValue) {
      // Fetch the data from the service_url
      const response = await fetch(media.service_url);
      // Read the response as a Blob
      const blob = await response.blob();
      
      // Create a mock file object with the Blob
      const mockFile = { 
        id: media.id,
        name: media.filename, 
        size: blob.size, 
        type: blob.type,
        accepted: true,
        dataURL: media.service_url // Create a URL for the Blob to use as a preview
      };
    
      // Add the mock file to Dropzone
      this.dropzone.emit("addedfile", mockFile);
      this.dropzone.emit("thumbnail", mockFile, mockFile.dataURL);
      this.dropzone.emit("complete", mockFile);
    }
  }

  get url() {
    return this.endpointUrlValue; // Use the endpointUrlValue here
  }
}