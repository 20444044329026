import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="copy"
export default class extends Controller {
  static targets = ["source", "icon"];

  async copy() {
    try {
      await navigator.clipboard.writeText(this.sourceTarget.textContent);
      M.toast({html: 'Copied to clipboard!'});
    } catch (err) {
      console.error('Failed to copy text: ', err);
      M.toast({html: 'Failed to copy!'});
    }
  }
  

  mouseOver() {
    this.iconTarget.classList.add("orange-text");
    const tooltipInstance = M.Tooltip.init(this.iconTarget);
    tooltipInstance.open();
}

mouseOut() {
    this.iconTarget.classList.remove("orange-text");
    const tooltipInstance = M.Tooltip.getInstance(this.iconTarget);
    if (tooltipInstance) {
        tooltipInstance.close();
    }
}
}

