import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox"
export default class extends Controller {
  static targets = ["checkbox"];

  // Called when the checkbox target is clicked
  toggleContent(event) {
    this.toggleDisplay(event, true);
  }

  // Called when the checkbox target is clicked
  toggleContentOff(event) {
    this.toggleDisplay(event, false);
  }

  toggleDisplay(event, isContentVisible) {
    const checkbox = event.currentTarget;
    const contentId = checkbox.dataset.contentId;
    const selectId = checkbox.dataset.selectId;
    const content = this.element.querySelector(`#${contentId}`);
    const original_select = this.element.querySelector(`#${selectId}`);

    if (content) {
      content.style.display = checkbox.checked === isContentVisible ? "block" : "none";
    }
    // if both exist
    if (original_select && selectId) {
      original_select.style.display = checkbox.checked === isContentVisible ? "none" : "block";
    }
  }
}