import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { count: Number, prefix: String, addButtonId: String }

  add(event) {
    event.preventDefault();
    let availableIds = [2, 3, 4, 5, 6];
    let addButton = document.getElementById(this.addButtonIdValue);
    for (let i = 2; i <= 6; i++) {
      let fields = document.getElementById(`${this.prefixValue}_${i}_fields`);
      if (fields.style.display !== 'none') {
        availableIds.splice(availableIds.indexOf(i), 1);
      }
    }
    if (availableIds.length > 1) {
      let nextId = Math.min(...availableIds);
      let fields = document.getElementById(`${this.prefixValue}_${nextId}_fields`);
      fields.style.display = 'block';
    } else if (availableIds.length == 1) {
        let nextId = Math.min(...availableIds);
        let fields = document.getElementById(`${this.prefixValue}_${nextId}_fields`);
        fields.style.display = 'block';
        if (addButton) {
          addButton.classList.add('disabled');
        }
    } else {
      if (addButton) {
        addButton.classList.add('disabled');
      }
    }
  }

  remove(event) {
    event.preventDefault();
    let number = event.target.dataset.addFieldRemoveValue;
    let fields = document.getElementById(`${this.prefixValue}_${number}_fields`);
    if (fields) {
      fields.style.display = 'none';

      // Clear the values of the input elements
      let inputs = fields.querySelectorAll('input');
      inputs.forEach(input => {
        input.value = '';
      });
    }
    let addButton = document.getElementById(this.addButtonIdValue);
    if (addButton) {
      addButton.classList.remove('disabled');
    }
  }
}