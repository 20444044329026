import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["earliest", "latest"];

  connect() {
    this.setInitialDateLimits();
    this.updateLatestMinDate();
    this.updateEarliestMaxDate();
  }

  setInitialDateLimits() {
    const today = this.formatDate(new Date());
    const assessmentCreationDate = this.data.get("assessmentCreationDate") || today; // Dynamically fetched date

    // Set initial limits
    const minEarliestDate = assessmentCreationDate > today ? assessmentCreationDate : today;
    this.earliestTarget.min = minEarliestDate;
    this.latestTarget.min = today;
  }

  updateLatestMinDate() {
    const earliestDate = this.earliestTarget.value;
    
    // Set minimum selectable date for the latest date picker to the selected earliest date
    if (earliestDate) {
      this.latestTarget.min = earliestDate;
    } else {
      this.setInitialDateLimits(); // Reset to initial state if no date selected
    }
  }

  updateEarliestMaxDate() {
    const latestDate = this.latestTarget.value;

    // Set maximum selectable date for the earliest date picker to the selected latest date
    if (latestDate) {
      this.earliestTarget.max = latestDate;
    } else {
      this.earliestTarget.removeAttribute("max"); // Remove the max limit if no date selected
    }
  }

  formatDate(date) {
    return date.toISOString().split("T")[0];
  }
}
