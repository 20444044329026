import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="carousel"
export default class extends Controller {
  connect() {
    this.initializeCarousels();
  }

  initializeCarousels() {
    this.element.querySelectorAll('.carousel.carousel-slider').forEach(element => {
      let instance = M.Carousel.init(element, {
        fullWidth: true,
        indicators: true
      });
      // Autoscroll at the specified interval or default to 10000 milliseconds
      const interval = this.intervalValue || 10000;
      setInterval(() => {
        instance.next();
      }, interval);
    });
  }
}
