import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="multi-upload"
export default class extends Controller {
  static targets = [ "output", "input" ]

  readURL() {
    let input = this.inputTarget
    let output = this.outputTarget
  
    if (input.files) {
      output.innerHTML = ''; // Clear the output area
  
      let title = document.createElement('h5');
      title.classList = "center";
      title.textContent = "Pending Uploads"
      output.appendChild(title);
      let topDivider = document.createElement('div');
      topDivider.classList = "divider";
      output.appendChild(topDivider);
  
      Array.from(input.files).forEach(file => {
        let reader = new FileReader();
  
        reader.onload = function (event) {
          if (file.type.startsWith('image')) {
            let img = document.createElement('img');
            img.src = event.target.result;
            img.classList = "col s6 l3 responsive-img materialboxed";
            output.appendChild(img);
          } else if (file.type.startsWith('video')) {
            let video = document.createElement('video');
            video.src = event.target.result;
            video.controls = true;
            video.classList = "col s6 l3 responsive-video materialboxed";
            output.appendChild(video);
          }
        }
  
        reader.readAsDataURL(file);
      });
    }
  }
}