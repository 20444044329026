import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="assessments-form"
// Stimulus controller
export default class extends Controller {


  change() {
      const avg_wt = Number(document.querySelector('#assessment_avg_wt').value);
      let headcount = Number(document.querySelector('#assessment_headcount').value);
      let tot_wt = document.querySelector('#total_wt');
      let total_wt = 0

      if (avg_wt !== 0) {
        total_wt = (avg_wt * headcount).toFixed(0);
      }
      tot_wt.value = Number(total_wt).toLocaleString();
    }
}
