import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.validateForm = this.validateForm.bind(this);
    this.removeExistingListener();
    this.element.addEventListener('submit', this.validateForm);
  }

  disconnect() {
    this.element.removeEventListener('submit', this.validateForm);
  }

  removeExistingListener() {
    this.element.removeEventListener('submit', this.validateForm);
  }

  validateForm(event) {
    let isValid = true;

    this.element.querySelectorAll('[data-required-if-visible="true"]').forEach(field => {
      // Check if the field or its parent container is visible
      if ($(field).is(':visible') || $(field).parent().is(':visible')) {
        if (!field.value.trim()) {
          isValid = false;
          this.showError(field);
          if (isValid === false) {
            if ($(field).is('select')) {
              // For Materialize select elements
              $(field).siblings('input.select-dropdown').addClass('invalid').focus();
              // for select2 elements
              $(field).siblings('span.select2').addClass('invalid').focus();
            } else if (field.matches('input[type="file"]')) {
              // For file input elements
              if (field.files.length === 0) {
                $(field).closest('.file-field').find('.file-path').addClass('invalid');
                field.focus();
              } else {
                $(field).closest('.file-field').find('.file-path').removeClass('invalid');
              }
            } else {
              field.focus();
            }
          }
        } else {
          this.clearError(field);
        }
      }
    });

    if (!isValid) {
      event.preventDefault();
      // Re-enable the submit button if validation fails
      this.element.querySelectorAll('input[type="submit"], button[type="submit"]').forEach(button => {
        button.disabled = false;
      });
    } else {
      // Optionally, disable the submit button after validation passes to prevent double submissions
      this.element.querySelectorAll('input[type="submit"], button[type="submit"]').forEach(button => {
        button.disabled = true;
      });
    }
  }

  showError(field) {
    if ($(field).is('select')) {
      // For Materialize select elements
      $(field).siblings('input.select-dropdown').addClass('invalid');
      // for select2 elements
      $(field).siblings('span.select2').addClass('invalid');
    } else {
      field.classList.add('invalid');
    }
  }

  clearError(field) {
    if ($(field).is('select')) {
      // For Materialize select elements
      $(field).siblings('input.select-dropdown').removeClass('invalid');
      // for select2 elements
      $(field).siblings('span.select2').removeClass('invalid');
    } else {
      field.classList.remove('invalid');
    }
  }
}
