import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="input-visibility"
export default class extends Controller {
  static targets = ["numberField", "imagesArea", "healthComments", "healthOtherField"];

  connect() {
    this.toggleVisibility();
  }

  toggleVisibility() {
    const hasInput = this.numberFieldTargets.some(field => field.value.trim() !== "");
    this.imagesAreaTarget.style.display = hasInput ? "block" : "none";

    const healthOtherField = this.healthOtherFieldTarget;
    if (healthOtherField) {
      const healthOtherHasInput = healthOtherField.value.trim() !== "";
      this.healthCommentsTarget.style.display = healthOtherHasInput ? "block" : "none";
    }
  }

  handleInput() {
    this.toggleVisibility();
  }
}
