import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sale-auction-type-increment"
export default class extends Controller {
  static targets = ["auctionType", "incrementField", "incrementInput"]

  connect() {
    this.toggleIncrement()
  }

  toggleIncrement() {
    const selectedValue = parseInt(this.auctionTypeTarget.value)
    const shouldShowIncrement = this.shouldShowIncrementField(selectedValue)

    if (shouldShowIncrement) {
      this.incrementFieldTarget.style.display = "block"
      this.incrementInputTarget.disabled = false
    } else {
      this.incrementFieldTarget.style.display = "none"
      this.incrementInputTarget.disabled = true
      this.incrementInputTarget.value = "" // Clear the value when hidden
    }
  }

  shouldShowIncrementField(auctionTypeId) {
    // Define your criteria here
    // For example, show increment field for auction types 1, 2, and 3
    const auctionTypesWithIncrement = [20, 21]
    return auctionTypesWithIncrement.includes(auctionTypeId)
  }
}
