import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="conditional-fields-production-system"
export default class extends Controller {
  static targets = ["livestockType"];
  static values = {
    treatments: Object,
    grazing: Object,
  };

  connect() {
    this.toggleFields();
  }

  toggleFields() {
    const selectedType = this.livestockTypeTarget.value.toLowerCase();
    const allTypes = ["sheep", "beef", "dairy"];

    allTypes.forEach((type) => {
      const elements = document.querySelectorAll(`.${type}-field`);
      elements.forEach((el) => {
        if (type === selectedType || selectedType === "") {
          el.classList.remove("hide");
        } else {
          el.classList.add("hide");
        }
      });
    });

    this.updateTreatments(selectedType);
    this.updateGrazing(selectedType);
  }

  updateTreatments(selectedType) {
    // Get the treatments for the selected livestock type
    const filteredTreatments = this.treatmentsValue[selectedType] || [];

    // Update all treatment select options
    const treatmentSelects = document.querySelectorAll(".treatment-select");
    treatmentSelects.forEach((treatmentSelect) => {
      this.updateMaterializeOptions($(treatmentSelect), filteredTreatments);
    });
  }

  updateGrazing(selectedType) {
    const grazingSelect = document.getElementById("grazing-select");
    this.updateMaterializeOptions(
      $(grazingSelect),
      this.grazingValue[selectedType]
    );
  }

  updateMaterializeOptions($select, options) {
    // Find the actual select element, whether it's directly selected or within a wrapper
    let select = $select.is("select")
      ? $select[0]
      : $select.find(".select-wrapper > select")[0];

    // If we still don't have a select element, log an error and return
    if (!select) {
      console.log("Select element not found", $select);
      return;
    }

    // Store the current value
    const currentValue = select.value;

    // Clear existing options
    select.innerHTML = "";

    // Add new options
    options.forEach((option) => {
      select.add(new Option(option, option));
    });

    // Restore the previous value if it still exists in the new options
    if (options.includes(currentValue)) {
      select.value = currentValue;
    }

    // Reinitialize Materialize select
    M.FormSelect.init(select);
  }
}
