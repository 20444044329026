import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pic-validator"
export default class extends Controller {
  static targets = ["input", "helperText"]

  connect() {
    this.validateInput()
    this.inputTarget.addEventListener('input', this.validateInput.bind(this))
  }

  validateInput() {
    const input = this.inputTarget
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z0-9]{8}$/; // Updated regex
    const isValid = regex.test(input.value)

    if (!this.hasHelperTextTarget) {
      this.addHelperText()
    }

    if (isValid) {
      input.classList.add('valid')
      input.classList.remove('invalid')
      this.helperTextTarget.textContent = '' // Remove text when valid
      this.helperTextTarget.classList.remove('red-text')
    } else {
      input.classList.remove('valid')
      input.classList.add('invalid')
      this.helperTextTarget.textContent = 'PIC must be 8 alphanumeric characters'
      this.helperTextTarget.classList.add('red-text')
    }
  }

  addHelperText() {
    const helperText = document.createElement('span')
    helperText.classList.add('helper-text')
    helperText.setAttribute('data-pic-validator-target', 'helperText')
    this.element.appendChild(helperText)
  }
}
