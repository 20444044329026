import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="onechecked"
export default class extends Controller {
  static targets = ["checkbox"];

  deselectOthers(event) {
    const selectedCheckbox = event.currentTarget;

    // Loop through all checkbox targets
    this.checkboxTargets.forEach(checkbox => {
      // If the current checkbox target is not the one that was changed, deselect it
      if (checkbox !== selectedCheckbox && checkbox.checked) {
        checkbox.checked = false;
        // Dispatch a change event
        checkbox.dispatchEvent(new Event('click'));
      }
    });
  }
}
