import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="vertical-stepper"
export default class extends Controller {
  initialize() {
    this.updateSteps();
  }

  updateSteps() {
    this.steps = Array.from(this.element.querySelectorAll('.step-vertical'));
  
    this.steps.forEach((step, index) => {
      step.addEventListener('click', () => {
        this.steps.forEach(step => step.classList.remove('active'));
        step.classList.add('active');
  
        // Scroll to the relevant section
        var section = document.getElementById('step' + (index + 1) + '-section');
        if (section && window.getComputedStyle(section).display !== 'none') {
          var sectionTop = section.getBoundingClientRect().top + window.pageYOffset;
          window.scrollTo({ top: sectionTop - 150, behavior: 'smooth' });
        }
      });
    });
  
    // Highlight the step corresponding to the currently visible section
    window.addEventListener('scroll', () => {
      this.steps.forEach((step, index) => {
        var section = document.getElementById('step' + (index + 1) + '-section');
        if (section && this.isInViewport(section) && window.getComputedStyle(section).display !== 'none') {
          this.steps.forEach(step => step.classList.remove('active'));
          step.classList.add('active');
        }
      });
    });
  }
  
  isInViewport(element) {
    if (window.getComputedStyle(element).display === 'none') {
      return false;
    }
  
    var rect = element.getBoundingClientRect();
    var windowHeight = (window.innerHeight || document.documentElement.clientHeight);
    return (
      rect.top >= 0 &&
      rect.top < windowHeight &&
      rect.top < windowHeight / 2
    );
  }
}