import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-cropper"
import Cropper from "cropperjs";

export default class extends Controller {
  static targets = ["imageInput", "imagePreview", "croppedImageData", "submitButton", "actualSubmitButton"];

  connect() {
    this.cropper = null;
    this.initializeModal();

    this.imageInputTarget.addEventListener('change', this.loadImage.bind(this));
    this.submitButtonTarget.addEventListener('click', this.cropAndSubmit.bind(this));
  }

  initializeModal() {
    const modalElem = document.querySelector('#logo');
    this.modalInstance = M.Modal.init(modalElem);
  }

  loadImage(e) {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);

    this.imagePreviewTarget.src = url;
    this.imagePreviewTarget.onload = () => {
      if (this.cropper) this.cropper.destroy();
      this.cropper = new Cropper(this.imagePreviewTarget, {
        aspectRatio: 1,
        viewMode: 1
      });
    };
    this.submitButtonTarget.disabled = false;
  }

  cropAndSubmit(e) {
    e.preventDefault();
    this.cropper.getCroppedCanvas().toBlob((blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.croppedImageDataTarget.value = reader.result;
        // Check if the record is new
        if (this.element.dataset.isNew === "true") {
          // If the record is new, update the preview image and don't submit the form
          
          this.imagePreviewTarget.src = reader.result;
          this.modalInstance.close();
          document.querySelector('#circle_profile_picture').src = reader.result;
          document.querySelector('#croppedImageDataNew').value = reader.result;
        } else {
          // If the record is not new, submit the form as usual
          this.modalInstance.close();
          this.actualSubmitButtonTarget.click();
        }
      };
    });
  }
}
