import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="progeny-combined"
export default class extends Controller {
  static targets = ["progeny", "combined"]

  calculate() {
    const progeny = Number(this.progenyTarget.value) || 0;
    const headcountElement = document.getElementById("assessment_headcount");
    const headcount = Number(headcountElement.value) || 0;
    const sexTypeElement = $("#assessment_sex_type");
    const sexType = sexTypeElement.val() || "";
    this.combinedTarget.innerHTML = `${headcount} X ${progeny} ${sexType}`;
  }
}