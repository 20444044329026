import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sinkable"
export default class extends Controller {
  static targets = ["type"];

  handleClick(event) {
    const button = event.target;
    const unit = button.textContent.trim(); // Get the unit from the button text

    // Add the 'white-text' class and change the color of the clicked element
    button.classList.add("white-text");
    button.classList.remove("white");
    button.style.color = "#CD7854";

    // Remove the 'white-text' class and change the color of the other elements
    this.element.querySelectorAll(".sinkable").forEach((el) => {
      if (el !== button) {
        el.classList.remove("white-text");
        el.classList.add("white", "black-text");
        el.style.color = ""; // Reset color
      }
    });

    // Update the hidden field value
    if (unit === "Not weighed") {
      this.typeTarget.value = "false";
      this.toggleUnweighedContent("weighed", "weight_ranges");
    } else if (unit === "Weighed") {
      this.typeTarget.value = "true";
      this.toggleWeighedContent("weight_ranges", "weighed");
    } else {
      this.typeTarget.value = unit;
    }

    this.typeTarget.dispatchEvent(new Event("change")); // Trigger the change event
  }

  toggleUnweighedContent(dom_id1, dom_id2) {
    // set the style display none for the element with the id dom_id
    document.getElementById(dom_id1).style.display = "none";
    document.getElementById(dom_id2).style.display = "block";
  }

  toggleWeighedContent(dom_id1, dom_id2) {
    // set the style display none for the element with the id dom_id
    document.getElementById(dom_id1).style.display = "block";
    document.getElementById(dom_id2).style.display = "block";
  }
}
