import { Controller } from "@hotwired/stimulus"
import debounce from "debounce";

// Connects to data-controller="filtered-search"
export default class extends Controller {
  static targets = ["loading", "pssearch", "perPageHidden"];
  initialize() {
    this.submit = debounce(this.submit.bind(this), 500)
  }

  connect() {
    document.querySelector("#role, #state, #sex, #site, #staff").addEventListener("change", (event) => {
      const selectedValue = event.target.value;
      const filter_tag = '<div class="chip" data-sticky-target="filterTag" id="filter_tag" style= "margin-top: 5px;" >' + event.target.value // + '<i class="close material-icons">close</i></div>';
      const current_filter = document.getElementById("filter_tag");
      if (current_filter) {
        current_filter.remove();
      }
      if (selectedValue) {
        let filter_div = document.getElementById('filter-div');
        filter_div.insertAdjacentHTML('beforeend', filter_tag);
      }
      this.submit(event);
    });
  }

  updatePerPage(event) {
    this.perPageHiddenTarget.value = event.target.value;
    let formElement = document.querySelector('form[data-turbo-action="advance"]');
    document.addEventListener("turbo:before-fetch-request", function(){
      document.getElementById('small-loader').style.display = 'inline-block';
    })
    formElement.requestSubmit();
    
    document.addEventListener("turbo:frame-render", function() {
      document.getElementById('small-loader').style.display = 'none';
  });
}

  submit(event) {
    let self = this;
    let formElement = document.querySelector('form[data-turbo-action="advance"]');
    document.addEventListener("turbo:before-fetch-request", function(){
      if (event.target.value) {
      document.getElementById('filter-div').style.display = 'block';
      } else if (event === null) {
        console.log("No Event To Trigger")
      } else {
        document.getElementById('filter-div').style.display = 'none';
      }
      self.loadingTarget.setAttribute("style", "display: inline-block");
  })
    formElement.requestSubmit();
    
    document.addEventListener("turbo:frame-render", function() {
      self.loadingTarget.setAttribute("style", "display: none"); 
  });
  
}
}
