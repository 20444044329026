import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="collapsible"
export default class extends Controller {
  connect() {
    const options = {
      onOpenEnd: this.handleOpenEnd.bind(this)
    };
    window.M.Collapsible.init(this.element, options);
  }

  handleOpenEnd(element) {

    this.initializeSelect2InCollapsible(element);


    const tabs = document.querySelectorAll('ul.tabs');
    window.M.Tabs.init(tabs);
  }

  initializeSelect2InCollapsible(element) {
    const select2Elements = element.querySelectorAll('select[data-controller="select2"]');
    select2Elements.forEach(select => {
      const select2Controller = this.application.getControllerForElementAndIdentifier(select, 'select2');
      if (select2Controller) {
        select2Controller.initializeSelect2();
      }
    });
  }
}
