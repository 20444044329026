import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    this.update();
  }

  update() {
    let total = 0;
    this.fieldTargets.forEach((field) => {
      const value = Number(field.value);
      if (!isNaN(value)) {
        total += value;
      }
    });

    const headcountElement = document.getElementById('assessment_headcount');
    const headcount = Number(headcountElement.value);

    const difference = headcount - total;

    this.fieldTargets.forEach((field) => {
      const value = Number(field.value);
      if (!isNaN(value)) {
        const percentage = (value / headcount) * 100;
        const percentageElement = field.nextElementSibling.nextElementSibling;
        percentageElement.textContent = `${percentage.toFixed(0)}%`;
        if (difference < 0) {
          percentageElement.classList.remove('green-text');
          percentageElement.classList.add('orange-text');
        } else if (difference > 0) {
          percentageElement.classList.remove('green-text');
          percentageElement.classList.add('orange-text');
        } else {
          percentageElement.classList.remove('orange-text');
          percentageElement.classList.add('green-text');
        }
      }
    });
  }
}