// app/javascript/controllers/age_range_controller.js

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["lower", "upper", "type"]
  
  connect() {
    this.typeTarget.addEventListener('change', this.validate.bind(this))
    
    // Check if either lower and upper is empty
    if (this.lowerTarget.value.trim() === "" || this.upperTarget.value.trim() === "") {
      return;
    }
  
    this.validate()
  }

  disconnect() {
    this.typeTarget.removeEventListener('change', this.validate.bind(this))
  }

  validate(event) {
    const ageRangeLower = this.lowerTarget.value
    const ageRangeUpper = this.upperTarget.value
    const ageRangeType = this.typeTarget.value;

    // Check if either lower and upper is empty
    if (this.lowerTarget.value.trim() === "" || this.upperTarget.value.trim() === "") {
      return;
    }

    // Remove existing error message
    const existingErrorMessage = this.element.querySelector('.helper-text.orange-text')
    if (existingErrorMessage) existingErrorMessage.remove()

    if (ageRangeLower && ageRangeUpper && ageRangeType === 'Months') {
      if (ageRangeUpper - ageRangeLower > 6) {
        // Create error message
        const errorMessage = document.createElement('span')
        errorMessage.classList.add('helper-text', 'orange-text')
        errorMessage.textContent = 'Max age range: 6 months'

        // Insert error message after age range lower field
        this.lowerTarget.parentNode.insertBefore(errorMessage, this.lowerTarget.nextSibling)
      }
    }
    if (ageRangeUpper - ageRangeLower <= 0) {
      // Create error message
      const errorMessage = document.createElement('span')
      errorMessage.classList.add('helper-text', 'orange-text')
      errorMessage.textContent = 'Invalid age range'

      // Insert error message after age range lower field
      this.lowerTarget.parentNode.insertBefore(errorMessage, this.lowerTarget.nextSibling)
    }
  }
}