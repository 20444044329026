import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="trim-whitespace"
export default class extends Controller {
  connect() {
    // Remove all spaces from the initial value
    this.element.value = this.element.value.replace(/\s+/g, '')

    // Add input event listener to remove spaces
    this.element.addEventListener('input', this.removeSpaces)
  }

  removeSpaces(event) {
    event.target.value = event.target.value.replace(/\s+/g, '')
  }
}