document.addEventListener('turbo:load', function() {
    // Handling #nextlot_sale change event
    let nextlotSale = document.getElementById('nextlot_sale');
    if (nextlotSale) {
        nextlotSale.addEventListener('change', function() {
            const isPublished = document.getElementById('is_published');
            const streamingSale = document.getElementById('streaming_sale');
            const nextlotId = document.getElementById('nextlot_id');

            if (nextlotSale.checked) {
                if (isPublished) isPublished.checked = false;
                if (streamingSale) streamingSale.checked = false;
                if (nextlotId) nextlotId.style.display = 'block';
            } else {
                if (isPublished) isPublished.checked = true;
                if (nextlotId) nextlotId.style.display = 'none';
            }
        });
    }

    // Handling #agrinous_sale change event
    let agrinousSale = document.getElementById('agrinous_sale');
    if (agrinousSale) {
        agrinousSale.addEventListener('change', function() {
            const agrinousId = document.getElementById('agrinous_id');

            if (agrinousSale.checked && agrinousId) {
                agrinousId.style.display = 'block';
            } else if (agrinousId) {
                agrinousId.style.display = 'none';
            }
        });
    }

    // Handling #nutrien_sale change event
    let nutrienSale = document.getElementById('nutrien_sale');
    if (nutrienSale) {
        nutrienSale.addEventListener('change', function() {
            const nutrienId = document.getElementById('nutrien_id');

            if (nutrienSale.checked && nutrienId) {
                nutrienId.style.display = 'block';
            } else if (nutrienId) {
                nutrienId.style.display = 'none';
            }
        });
    }
});


  
  
  
  

