import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="character-counter"
export default class extends Controller {
  connect() {
    this.initializeCounter()
  }

  initializeCounter() {
    if (window.M && window.M.CharacterCounter) {
      window.M.CharacterCounter.init(this.element)
    } else {
      // Wait for Materialize to be available
      this.waitForMaterialize()
    }
  }

  waitForMaterialize() {
    const checkInterval = setInterval(() => {
      if (window.M && window.M.CharacterCounter) {
        window.M.CharacterCounter.init(this.element)
        clearInterval(checkInterval)
      }
    }, 100)

    // Stop checking after 5 seconds to prevent infinite loop
    setTimeout(() => {
      clearInterval(checkInterval)
      if (!window.M?.CharacterCounter) {
        console.error("Materialize CharacterCounter not available after timeout")
      }
    }, 5000)
  }

  disconnect() {
    if (window.M?.CharacterCounter) {
      const instance = window.M.CharacterCounter.getInstance(this.element)
      if (instance) {
        instance.destroy()
      }
    }
  }
}
