import consumer from "./consumer"

let element = document.getElementById('assessment_id');
let assessmentId = element ? element.dataset.assessmentId : null;

if (assessmentId) {
  consumer.subscriptions.create({ channel: "AssessmentsChannel", id: assessmentId.toString() }, {
    connected() {
      // Called when the subscription is ready for use on the server
    },
  
    disconnected() {
      // Called when the subscription has been terminated by the server
    },
  
    received(data) {
      //show the weights div
      let weights = document.getElementById('weights');
      weights.style.display = 'block';

      // set the values of th weight ranges fields
      let weightRanges = data.weight_ranges;

      for (let range in weightRanges) {
        let fieldId = 'assessment_wt_';
        if (range === '0-200') {
          fieldId += '200_less';
        } else if (range === '600+') {
          fieldId += '600_plus';
        } else {
          fieldId += range.replace('-', '_');
        }
        let field = document.getElementById(fieldId);
        if (field) {
          field.value = ''; // Clear the field value
          field.value = weightRanges[range]; // Assign the new value
          field.dispatchEvent(new InputEvent('input'));
          field.setAttribute("readonly", "readonly"); // Make the field read-only
        }
      }
  
      //set the values of the avg_wt field
      let estWtElement = document.getElementById('assessment_avg_wt');
      // make the element readonly
      estWtElement.setAttribute('readonly', 'readonly');
      // update the value
      estWtElement.value = data.avg_wt;
      // trigger an input event to update the total wt based on the average
      estWtElement.dispatchEvent(new InputEvent('input'));
  
      //set the values of the head_weighed field
      let headWeighedElement = document.getElementById('head_weighed');
      // make it readonly
      headWeighedElement.setAttribute('readonly', 'readonly');
      // update the value
      headWeighedElement.value = data.head_imported;
      // trigger an input event to update the percentage weighed
      headWeighedElement.dispatchEvent(new InputEvent('input'));
      let heaviestWeight = document.getElementById('assessment_heaviest_wt');
      // make it readonly
      heaviestWeight.setAttribute('readonly', 'readonly');
      heaviestWeight.value = data.heaviest_animal;
      
  
      let manualWeights = document.getElementById('assessment_manual_weighed');
      // add the class of disabled to it
      manualWeights.classList.add('disabled');

      let lightestWeight = document.getElementById('assessment_lightest_wt');
      lightestWeight.value = data.lightest_animal;
      // make it readonly
      lightestWeight.setAttribute('readonly', 'readonly');


      // Called when there's incoming data on the websocket for this channel
    }
  });
}

