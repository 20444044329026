import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="realtime-upload"
export default class extends Controller {
  submit() {
    let saveAllButton = document.querySelector('[data-save-all-target="saveAllButton"]');
    if (saveAllButton && saveAllButton.textContent.trim() === 'Save progress') {
      saveAllButton.click();
    }
  }
}
