import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="check-form"
export default class extends Controller {
  static targets = ["input", "button"];

  connect() {
    this.checkFormInputs();
  }

  checkFormInputs() {
    const allFilled = this.inputTargets.every((input) => input.value !== "");
    this.buttonTarget.disabled = !allFilled;
  }

  onInputChange() {
    this.checkFormInputs();
  }
}
