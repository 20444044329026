import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hover"
export default class extends Controller {

  connect() {
    this.element.addEventListener("mouseover", () => {
      const targetId = this.element.getAttribute('data-hover-target');
      const target = document.getElementById(targetId);
      target.classList.add("open");
    })
    this.element.addEventListener("mouseout", () => {
      const targetId = this.element.getAttribute('data-hover-target');
      const target = document.getElementById(targetId);
      target.classList.remove("open");
    })
  }

}
