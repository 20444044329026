import { Controller, add } from "@hotwired/stimulus"

// Connects to data-controller="price-addition"
export default class extends Controller {
  static targets = [ "checkbox", "calc", "totalPrice", "head", "customCheckbox" ]


  multiply() {
    let headcount = 0;
    let price = 0;

    if (this.headTarget.value) {
      console.log(this.customCheckboxTarget.checked)
      headcount += parseInt(this.headTarget.value);

      if (headcount <= 25) {
        price = 2000
      } else if (headcount <= 50) {
        price = (25 * 80) + ((headcount - 25) * 50);
      } else {
        price = (25 * 80) + (25 * 50) + ((headcount - 50) * 30);
      }
      this.calcTarget.value = price
      this.calculate()
    }
    else {
      this.calcTarget.value = 0
      this.calculate()
    }
  }
  calculate() {
    let totalPrice = 0

    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        totalPrice += parseInt(checkbox.dataset.price)
      }
    })
    if (this.customCheckboxTarget.checked) {
      if (this.calcTarget.value) {
        totalPrice += parseInt(this.calcTarget.value)
      }
    }

    this.totalPriceTarget.value = (totalPrice * 1.1).toFixed(2);}
}