import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="quickfillhd"
export default class extends Controller {
  static targets = ["field"]

  updateField(event) {
    const fieldName = event.target.dataset.fieldName
    const value = event.target.dataset.value

    // Find the field to update
    const field = this.fieldTargets.find(target => target.name === fieldName)
    // Update the field value
    if (field) {
      field.value = value
      field.dispatchEvent(new Event('input', { bubbles: true }))
    } else {
      console.log('Field not found'); // Debugging statement
    }

    // Clear the other fields
    this.fieldTargets
      .filter(target => target.name !== fieldName)
      .forEach(target => {
        target.value = ""
        target.dispatchEvent(new Event('input', { bubbles: true }))
      })
  }
}
